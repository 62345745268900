import Image from "next/image"
import { memo } from "react"

import BMW from "@spatialsys/assets/img/brands/brand-partnerships/bmw.webp"
import Boss from "@spatialsys/assets/img/brands/brand-partnerships/boss.webp"
import ElleMagazine from "@spatialsys/assets/img/brands/brand-partnerships/elle-magazine.webp"
import GameStop from "@spatialsys/assets/img/brands/brand-partnerships/game-stop.webp"
import Givenchy from "@spatialsys/assets/img/brands/brand-partnerships/givenchy.webp"
import Hublot from "@spatialsys/assets/img/brands/brand-partnerships/hublot.webp"
import JackDaniels from "@spatialsys/assets/img/brands/brand-partnerships/jack-daniels.webp"
import McDonalds from "@spatialsys/assets/img/brands/brand-partnerships/mcdonalds.webp"
import TommyHilfiger from "@spatialsys/assets/img/brands/brand-partnerships/tommy-hilfiger.webp"
import UtahJazz from "@spatialsys/assets/img/brands/brand-partnerships/utah-jazz.webp"
import Vogue from "@spatialsys/assets/img/brands/brand-partnerships/vogue.webp"
import Walmart from "@spatialsys/assets/img/brands/brand-partnerships/walmart.webp"

const BRANDS = [
  {
    name: "Boss",
    image: Boss,
  },
  {
    name: "Utah Jazz",
    image: UtahJazz,
  },
  {
    name: "Walmart",
    image: Walmart,
  },
  {
    name: "Jack Daniels",
    image: JackDaniels,
  },
  {
    name: "BMW",
    image: BMW,
  },
  {
    name: "Vogue",
    image: Vogue,
  },
  {
    name: "McDonalds",
    image: McDonalds,
  },
  {
    name: "Hublot",
    image: Hublot,
  },
  {
    name: "GameStop",
    image: GameStop,
  },
  {
    name: "Givenchy",
    image: Givenchy,
  },
  {
    name: "Elle Magazine",
    image: ElleMagazine,
  },
  {
    name: "Tommy Hilfiger",
    image: TommyHilfiger,
  },
]

export const Gallery = memo(function Gallery() {
  return (
    <div className="grid w-full grid-cols-3 justify-items-center gap-8 xs:gap-4 sm:gap-8 md:w-auto md:grid-cols-4">
      {BRANDS.map(({ name, image }) => (
        <Image height="80" width="80" alt={name} key={name} src={image} />
      ))}
    </div>
  )
})
