import Link from "next/link"
import { memo } from "react"
import { Balancer } from "react-wrap-balancer"

import { ReactComponent as ArrowRightIcon } from "@spatialsys/assets/icons/lucide/arrow-right.svg"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { Button, Container, Heading, Text } from "@spatialsys/web/ui"

import { Gallery } from "./gallery"

export const BrandExperiences = memo(function BrandExperiences() {
  return (
    <TrackedComponent id={TrackedComponents.BrandExperiences} as="div">
      <Container className="grid items-center justify-items-center gap-6 xs:grid-cols-2 xs:gap-8">
        <Gallery />
        <div className="row-start-1 grid justify-items-center gap-2 xs:row-start-auto xs:justify-items-start xs:gap-4 md:gap-6">
          <Balancer>
            <Heading size="m3" className="text-center text-m3 xs:text-left lg:text-m2" weight="black">
              Premium Brand Experiences On Any Device
            </Heading>
          </Balancer>
          <Text className="text-center text-sm text-muted-foreground xs:text-left md:text-base lg:text-lg">
            Explore immersive worlds and incredible games by your favorite brands. Play across web, VR, and mobile!
          </Text>
          <Button
            as={Link}
            href="/brands"
            className="mx-auto mt-2 w-fit xs:mx-0 xs:mt-0"
            color="white"
            size="md"
            rightIcon={<ArrowRightIcon className="icon icon-xs" />}
          >
            Explore Now
          </Button>
        </div>
      </Container>
    </TrackedComponent>
  )
})
